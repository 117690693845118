
import { Routes } from '../config';

function App() {
  return (
    <Routes />
  );
}

export default App;
