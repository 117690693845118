import React, { useState, useEffect, useRef } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import '../../assets/css/bootstrap.css';
import '../../assets/css/templatemo-style.css';
import '../Rewards/Rewards.css';
import Header from '../../component/Header';
import SideBar from '../../component/SideBar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Rewards = () => {
  const [rewardInfo, setRewardInfo] = useState([]);
  const [paymentAddress, setPaymentAddress] = useState();

  const tokenRef = useRef();
  let navigate = useNavigate();

  const getRewardInfo = async () => {
    await axios
      .get('https://xmrminerpro.com/api/vouchers/getPoints')
      .then(({ data }) => {
        setRewardInfo(data?.data);
      });
  };
  useEffect(() => {
    const loginUser = localStorage.getItem('user');
    if (loginUser) {
      const foundUser = JSON.parse(loginUser);
      tokenRef.current = foundUser?.token;
      setPaymentAddress(foundUser?.username);
    }
    getRewardInfo();
  }, []);

  const toDashboard = () => {
    navigate('/dashboard', { state: { login: true } });
  };

  const claimReward = async (point, desc) => {
    await axios
      .post(
        `https://xmrminerpro.com/api/point/${paymentAddress}/deductin`,
        {
          amount_outcome: point,
          description_outcome: desc,
        },
        { headers: { 'x-access-token': tokenRef?.current } }
      )
      .then((res) => {
        toDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div id="wrapper">
        {/* Main */}
        <div id="main">
          {/* Header */}
          <Header />
          <div className="inner">
            <section className="container-fluid">
              <div className="row">
                <div className="col-12 headings mg-5">
                  <h1>Rewards</h1>
                </div>
                <div className="divider"></div>
                <div className="row mt-5">
                  {rewardInfo?.map((data) => (
                    <div className="col-md-4">
                      <div className="card ml-5">
                        <div class="card-header text-center bg-warning">
                          {data?.description_short}
                        </div>
                        <img
                          style={{ cursor: 'pointer' }}
                          className="card-img-top"
                          src={'assets\\images\\voucher\\' + data?.image}
                          alt="Card image cap"
                          height={'150px'}
                          onClick={() => {
                            window.open(data?.url, '_blank');
                          }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">{data?.points} points</h5>

                          <h6 className="card-title">{data?.price} $ </h6>
                          <hr />
                          <button
                            onClick={() => {
                              claimReward(
                                data?.points,
                                data?.description_short
                              );
                            }}
                            className="btn btn-primary "
                          >
                            Redeem Point
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Sidebar */}
        <SideBar />
      </div>
    </Fragment>
  );
};

export default Rewards;
